import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppointmentPK } from "../model/AppointmentPK";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class AvisService {
  constructor(private http: HttpClient) { }


  url = environment.apiURL + "rendez-vous-service/avis";
  
  save(data: any): Observable<any> {

    return this.http.post<any>(this.url + "/save", data, httpOptions);
  }


  getAll(): Observable<any> {
    return this.http.get<any>(this.url + "/all", httpOptions);
  }


  getbyProfessionnel(id:any): Observable<any> {
    return this.http.get<any>(this.url + "/getAvisProfessionel/"+id, httpOptions);
  }


  getAvis(rdv:AppointmentPK): Observable<any> {
   //console.log(rdv);
    
    return this.http.post<any>(this.url + "/getAvis",rdv, httpOptions);
  }

  
  getByUser(id:any): Observable<any> {
    return this.http.get<any>(this.url + "/all/"+id, httpOptions);
  }

  update(id: any, data: any): Observable<any> {

    return this.http.put<any>(this.url + "/update/" + id, data, httpOptions);
  }

  delete(id: any): Observable<any> {

    return this.http.delete<any>(this.url + "/delete/" + id);
  }



}