import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { Media } from "../user-service/model/Media";

@Component({
  selector: "app-picture-upload",
  templateUrl: "./picture-upload.component.html",
  styleUrls: ["./picture-upload.component.scss"]
})
export class PictureUploadComponent implements OnInit {
  @Input() avatar: boolean = false;
  @Input() image: any;

  @Output() mediaEvent= new EventEmitter(); 
  file: any = {};
  media:Media=new Media();
  imagePreviewUrl: any = {};
  @ViewChild("fileInput") fileInput: any;
  constructor() {
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  ngOnInit() {
    this.file = null;
    this.imagePreviewUrl =
      this.image !== undefined
        ? this.image
        : this.avatar
        ? "assets/img/placeholder.jpg"
        : "assets/img/image_placeholder.jpg";
  }
  handleImageChange($event:any) {
    $event.preventDefault();
    let reader = new FileReader();
    let file = $event.target.files[0];
    
    reader.onloadend = () => {
      this.file = file;
      this.media.fileComplet=file;
      //console.log("yes fil");
      this.imagePreviewUrl = reader.result;
      //console.log("this.imagePreviewUrl",this.imagePreviewUrl);
      
      // this.state.imagePreviewUrl1 = reader.result;
    };
    this.media.fileComplet=this.file;
    reader.readAsDataURL(file);
  }
  handleClick() {
    //console.log(this.fileInput.nativeElement);
    this.fileInput.nativeElement.click();
  }
  handleRemove() {
    this.file = null;
    this.imagePreviewUrl =
      this.image !== undefined
        ? this.image
        : this.avatar
        ? "assets/img/placeholder.jpg"
        : "assets/img/image_placeholder.jpg";
    this.fileInput.nativeElement.value = null;
  }
  handleSubmit($event:any) {
    $event.preventDefault();
    //console.log("uplooooods image work now ",this.file);
    
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  handleSave(){
  
    const base64result = this.imagePreviewUrl.substr(this.imagePreviewUrl.indexOf(',') + 1);
    
    this.media.type="image";
    this.media.fileName=this.file.name; 
    this.media.file=  base64result; 
    this.mediaEvent.emit(this.media);
     

    
  }

 
}
