import { Address } from "./Address";
import { Profile } from "./Profile";
import { Role } from "./Role";
import { Speciality } from "./Speciality";

export class User {
    id?:number;
    keycloak?: String ;
    cin?:number;
    matriculeFiscale?:String;
    verified?:Boolean;
    username?: String ;
    name?: String ;
    firstName?: String ;
    lastName?: String ;
    password?: String ;
    email?: String ;
    role?: Role[] ;
    phone? :number;
    address? : Address;
    profile?:Profile;
    Otp?:number ;
    speciality?: Speciality[] ;
    
}