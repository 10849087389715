import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PickerModule } from '@ctrl/ngx-emoji-mart';



import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenubarModule } from 'primeng/menubar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { CalendarModule } from 'primeng/calendar';
import { MegaMenuModule } from 'primeng/megamenu';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {GMapModule} from 'primeng/gmap';
import { StepsModule } from 'primeng/steps';
import {RatingModule} from 'primeng/rating';
import {PickListModule} from 'primeng/picklist';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PictureUploadComponent } from '../picture-upload/picture-upload.component';
import { MaterialModule } from './material.module';

import {DropdownModule} from 'primeng/dropdown';

import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listGridPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";


import { RatingComponent } from '../rendez-vous-service/rating/rating.component';

import {FlexLayoutModule} from "@angular/flex-layout";
import { AjoutComponent } from '../reclamation-service/ajout/ajout.component';



FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin,
   timeGridPlugin,
   listGridPlugin,

]);

@NgModule({
  declarations: [
    PictureUploadComponent,
    RatingComponent,
    AjoutComponent,
  ],
  imports: [
    FlexLayoutModule,
    PickListModule,
    RatingModule,
    GMapModule,
    CommonModule,
    MegaMenuModule,
    InputNumberModule,
    ToolbarModule,
    ConfirmDialogModule,
    StepsModule,
    PanelMenuModule,
    MenubarModule,
    //GMapModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    TagModule,
    DialogModule, ButtonModule, ProgressbarModule, InputTextModule,


    
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,

    FullCalendarModule,

    
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),    
    NgbModule,
    PickerModule,
    MaterialModule,
    
  ]
,
  exports: [   
    FlexLayoutModule,
    PickListModule,
    RatingModule,
    GMapModule,
    CommonModule,
    MegaMenuModule,
    InputNumberModule,
    ToolbarModule,
    ConfirmDialogModule,
    StepsModule,
    PanelMenuModule,
    MenubarModule,
    //GMapModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    TagModule,
    DialogModule, ButtonModule,  ProgressbarModule, InputTextModule,
    FullCalendarModule,

    DropdownModule,
    
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule,
    ProgressbarModule,
    TooltipModule,
    TimepickerModule,
    PopoverModule,
    CollapseModule,
    TagInputModule,
    TabsModule,
    PaginationModule,
    AlertModule,
    BsDatepickerModule,
    CarouselModule,
    NgbModule,
    PickerModule,
    ModalModule,
    PictureUploadComponent,
    RatingComponent,
    AjoutComponent,
    MaterialModule,
  ]
})
export class SharedModule { }
