import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/user-service/service/token-storage.service';
import { Reclamation } from '../model/Reclamation';
import { ReclamationService } from '../service/reclamation.service';

@Component({
  selector: 'app-ajout',
  templateUrl: './ajout.component.html'
  
})
export class AjoutComponent implements OnInit {

  focus1:any;
  focus2:any;

  data:Reclamation=new Reclamation();
  user :any = {};
  types:any =[];
  isChecked=false;
  msg =""
  success=false;
  @Input() typeReclamation :any ; 
   
  constructor(private ts:TokenStorageService , private reclamationService:ReclamationService,private router:Router) { }

  
  ngOnInit(): void {
    this.user = this.ts.getUser();
    this.reclamationService.getAllTypes().subscribe(res=>
      this.types=res)
   
    if(this.typeReclamation==undefined)
    this.data.type={name:"autre"};
    else     this.data.type={name:this.typeReclamation};

    
  }
  OnChecked()
  {
    this.isChecked=true;
  }
 
  Envoyer(){
    //console.log(this.data);
    
    this.data.dateCreation=new Date();
    this.data.user=this.user;
    this.data.etat="en_cours"
    if(this.data.description!==undefined)
    {
      this.reclamationService.save(this.data).subscribe(res=>{
        //console.log(res);
        if(this.typeReclamation==undefined)
        this.router.navigate(['reclamation/list']);
        else  this.data = new Reclamation();

        
      })
    }
    else this.msg = "champs vide";
    

  }

}
