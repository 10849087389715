import { User } from "src/app/user-service/model/User";
import { Type_reclamation } from "./TypeReclamation";

export class Reclamation {
    id?: number;
    titre?: String;
    dateCreation?: Date;
    description?: String;
    user: User = new User();
    type?: Type_reclamation;
    etat?: String;

    firstName: String = "";
    lastName: String = "";
    phone: String = "";
    email: String = "";



}
