import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReclamationService {

  constructor(private http: HttpClient) { }


  url = environment.apiURL+"reclamation-service/reclamation";
  
  save(reclamation: any): Observable<any> {

    return this.http.post<any>(this.url + "/save", reclamation, httpOptions);
  }


  getAll(): Observable<any> {
    return this.http.get<any>(this.url + "/all", httpOptions);
  }

  
  getByUser(id:any): Observable<any> {
    return this.http.get<any>(this.url + "/all/"+id, httpOptions);
  }

  update(id: any, reclamation: any): Observable<any> {

    return this.http.put<any>(this.url + "/update/" + id, reclamation, httpOptions);
  }

  delete(id: any): Observable<any> {

    return this.http.delete<any>(this.url + "/delete/" + id);
  }

  getAllTypes(): Observable<any> {
    return this.http.get<any>(environment.apiURL+"reclamation-service/types/all", httpOptions);
  }






}