import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TokenStorageService } from 'src/app/user-service/service/token-storage.service';
import { AvisService } from '../service/avis.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html'
})
export class RatingComponent implements OnInit {

  @Input() professionelId :any ;
  @Input() isClient = false ;
  @Input() rdv :any;


  val : number = 0 ;

  

  constructor(private avisService:AvisService,private tokenStorageService:TokenStorageService) { }
  
  ngOnInit(): void {

   
    
    if(this.isClient)
    this.getbyProfessionnelandclient();
    else
    this.getbyProfessionnel();
    
  }

  getbyProfessionnel(){
    this.avisService.getbyProfessionnel(this.professionelId).subscribe(res=>
      this.val = res 
      );
  }
  
  getbyProfessionnelandclient(){
    this.avisService.getAvis(this.rdv).subscribe(res=>
      {
       //console.log(" getbyProfessionnelandclient " + res);
        
        this.val = res  ;
      });
  }

}
