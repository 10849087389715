import { Injectable } from '@angular/core';
import { User } from '../model/User';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public signOut(): void {
    //window.localStorage
    window.localStorage.clear();

  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {

    let x : any = localStorage.getItem(TOKEN_KEY) ;
    return x;
    
  }

  public saveUser(user:User): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));

  }

  public getUser(): any {

    let x : any = localStorage.getItem(USER_KEY) ;
    return JSON.parse(x);
  }
}