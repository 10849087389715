<div class="cd-section" id="contact-us">
    <div class="contactus-1" style="background-color: #8898aa; background-image: url('assets/img/ill/1.svg')">
        <div class="container">
            <div class="row">
                <div class="col-md-8 py-5">
                    <h2 class="title text-secondary">Réclamation</h2>
                    <h4 class="description text-secondary">
                        Avez-vous besoin de plus d'informations? Veuillez nous contacter pour en savoir plus
                        sur nos produits et services.
                    </h4>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12 mx-auto">
                    <div class="card card-contact card-raised">
                        <div class="row">
                            <div class="col-lg-9 col-md-6 mx-auto">
                                <form class="p-auto" id="contact-form" method="post" role="form">

                                    <div class="card-body">


                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label> Téléphone </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="fa fa-phone"> </i>
                                                            </span>
                                                        </div>
                                                        <input class="form-control" type="text" [(ngModel)]="user.phone"
                                                            name="phone" disabled=true />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label> Email </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="fa fa-envelope-open"> </i>
                                                            </span>
                                                        </div>
                                                        <input aria-label="Nom..." class="form-control"
                                                            placeholder="Nom.." type="text" [(ngModel)]="user.username"
                                                            name="username" disabled=true />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="form-group" [ngClass]="{ 'focused': focus1 === true }">
                                            <label> Titre </label>
                                            <div class="input-group">

                                                <input aria-label="Titre..." class="form-control" placeholder="Titre.."
                                                    type="text" [(ngModel)]="data.titre" name="titre"
                                                    (blur)="focus1 = false" (focus)="focus1 = true" />
                                            </div>
                                        </div>
                                        <div class="form-group" [ngClass]="{ 'focused': focus2 === true }">
                                            <label> Description </label>
                                            <textarea class="form-control" (click)="success=false"
                                                [(ngModel)]="data.description" name="description"
                                                (blur)="focus2 = false" (focus)="focus2 = true" rows="6"></textarea>

                                            {{msg}}
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="custom-control custom-checkbox mb-3">
                                                    <input class="custom-control-input" [(ngModel)]="isChecked"
                                                        (click)="OnChecked()" name="isChecked" id="customCheck2"
                                                        type="checkbox" />

                                                    <label class="custom-control-label" for="customCheck2">
                                                        <span> Je ne suis pas un robot </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <button *ngIf="isChecked" class="btn btn-default pull-right"
                                                    type="submit" (click)="Envoyer()">
                                                    Envoyer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                    <alert class="d-flex justify-content-center fixed-bottom" *ngIf="success" [dismissible]="true"
                        [type]="'success'">

                        <span class="alert-inner--text">
                            Votre réclamation a été envoyée avec success !! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>

                    </alert>

                </div>




            </div>

        </div>
    </div>