import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    
      {
        path: '',
        redirectTo: '/accueil',
        pathMatch: 'full'
        
      },
      { path: '', loadChildren: () => import('src/app/home-visitor/home-visitor.module').then(m => m.HomeVisitorModule) },
      // { path: '', loadChildren: () => import('src/app/home-layout/home-layout.module').then(m => m.HomeLayoutModule) },

    


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
